.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input-account{
    border: 1px solid silver !important;
    padding: 15px;
    margin: 6px;
    border-radius: 3px;
    align-self: center;
}
select{
    appearance: none;

}
.select-account{
    border: 1px solid silver !important;
    padding: 15px;
    margin: 4px;
    border-radius: 3px;
    align-self: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.optionAccount{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.forminput{
    position: relative;
    height: 45px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 95px;
    
}
.namesinput{
    position: relative;
    height: 45px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}
.eye{
    font-size: 18px;
    color: #8b8b8b;
    position: absolute;
    transform: translateY(-50%);
    right: 25px;
    top: 115%;
    cursor: pointer;
    padding: 5px;
}
.eyelogin{
    font-size: 18px;
    color: #8b8b8b;
    position: absolute;
    transform: translateY(-50%);
    right: 50px;
    top: 115%;
    cursor: pointer;
    padding: 5px;
}
@media only screen and (max-width:575px) {
   .eyelogin{
    right:140px !important;
   } 
}
.btnLogin{
    width: 140px;
    height: 50px;
    background-color: #5228F5;
    color: white!important;
    padding: 5px;
    align-items: center;
    justify-content: center;
    margin: 10px;
    margin-top: 15px;
}
.labelTextNames{
    color:grey;
    font-size: 15;
    margin-left: 40px;
}

.labelText{
    color:grey;
    font-size: 15;
    margin-left: 6px;
}


