.home-action-bar {
    background-color: white;
    width: 100%;
    display: flex;
    padding: 120px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-action {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: flex-end;
    border-radius: 50px;
    flex-direction: row;
    justify-content: space-between;
    background-color: #036ea8 !important;
  }
  .home-heading3 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-sevenunits);
    padding-left: var(--dl-space-space-sevenunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-text095 {
    color: var(--dl-color-gray-white);
    text-align: left;
  }
  .home-text099 {
    color: #EEE9FE;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-get-started8 {
    margin-bottom: 0px;
  }
  .home-text100 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .home-images {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-image1 {
    width: 100%;
    object-fit: cover;
    user-select: none;
  }
  .home-faq {
    width: 100%;
    display: flex;
    padding: 120px;
    align-items: center;
    flex-direction: column;
    padding-bottom: 120px;
    justify-content: center;
    background-color: #EFF0F2;
  }