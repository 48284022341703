@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,500&display=swap');

html,
body,
#root,
.app,
.content
{
    height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
}
.app{
    display: flex;
    position: relative;
}
