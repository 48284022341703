.contentBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 20px;
}
@media only screen and (max-width:768px) {
    .contentBox{
        flex-direction: column!important;
    }
}